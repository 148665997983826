import React, { useEffect, useState } from "react";
import {
  HeaderSticky,
  MyTours,
  ProviderBooking,
  ProviderSideBar,
  ProviderSpeedDial,
  ProviderUpcoming,
  ProviderOrders,
  ProfileProvider,
  ProviderWishlist,
  ProviderTransactionHistory,
  PartnerCommissionHistory,
} from "../components";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Dashboard from "../components/ProviderAccount/Dashboard";
import SupplierPage from "../components/ProviderAccount/SupplierPage";
import SupplierPayment from "../components/ProviderAccount/SupplierPayment";
import Analytics from "../components/ProviderAccount/Analytics";
import { ProviderContext } from "../context";
import ProviderReviews from "../components/ProviderAccount/ProviderReviews";
import MyMaps from "../components/UserProfile/MyMap";
import { useSelector } from "react-redux";
import ReservationSystem from "../components/ProviderAccount/ReservationSystem";
import ReadPolicyModal from "../components/Modal/ReadPolicyModal";

const ProviderAccount = () => {
  const profileReducer = useSelector((state) => state.profileReducer);
  const [payPalAccountSetup, setPayPalAccountSetup] = useState("");
  const location = useLocation();
  const [activeAccordian, setActiveAccordian] = useState("");
  const [isReviewModal, setIsReviewModal] = useState(false);

  useEffect(() => {
    if (profileReducer) {
      setPayPalAccountSetup(profileReducer.is_account_setup);
      
      if (profileReducer?.is_read_policy == 0) {
        setIsReviewModal(true);
      }
    }
  }, [profileReducer]);

  return (
    <ProviderContext.Provider
      value={{
        activeAccordian,
        setActiveAccordian,
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        {profileReducer && (
          <div className="provider_account_main">
            {/* <ProviderHeader /> */}
            <HeaderSticky />
            <div className="provider_dispaly">
              <div className="provider_slider_bar">
                <ProviderSideBar />
              </div>

              <div className="provider_speeddail_mobile">
                <ProviderSpeedDial />
              </div>

              <div className="provider_all_component provider_all_data">
                {/* <div className="provider_all_data"> */}
                {location.pathname == "/supplier-wishlist" ? (
                  <ProviderWishlist />
                ) : location.pathname === "/supplier-page" ? (
                  <SupplierPage />
                ) : location.pathname === "/dashboard" ? (
                  <Dashboard />
                ) : location.pathname === "/provider-map" ? (
                  <MyMaps />
                ) : location.pathname === "/supplier-payment" ? (
                  <SupplierPayment />
                ) : location.pathname === "/profile-supplier" ? (
                  <ProfileProvider />
                ) : payPalAccountSetup != undefined &&
                  payPalAccountSetup != "" &&
                  payPalAccountSetup > 0 ? (
                  location.pathname === "/my-tours" ? (
                    <MyTours />
                  ) : location.pathname === "/supplier-upcoming" ? (
                    <ProviderUpcoming />
                  ) : location.pathname === "/supplier-booking" ? (
                    <ProviderBooking />
                  ) : location.pathname === "/supplier-orders" ? (
                    <ProviderOrders />
                  ) : location.pathname === "/supplier-transaction-history" ? (
                    <ProviderTransactionHistory />
                  ) : location.pathname === "/supplier-commission-history" ? (
                    <PartnerCommissionHistory />
                  ) : location.pathname === "/reservation-system" ? (
                    <ReservationSystem />
                  ) : location.pathname === "/provider-analytics" ? (
                    <Analytics />
                  ) : location.pathname === "/provider-reviews" ? (
                    <ProviderReviews />
                  ) : (
                    <Dashboard />
                  )
                ) : (
                  <Dashboard />
                )}
                {/* </div> */}
                {isReviewModal && (
                  <ReadPolicyModal
                    isReviewModal={isReviewModal}
                    setIsReviewModal={setIsReviewModal}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </ProviderContext.Provider>
  );
};

export default ProviderAccount;

import { useContext, useEffect, useRef } from "react";
import { images } from "../../actions/customFn";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "animate.css";
import { useState } from "react";
import LoginModal from "../Modal/LoginModal";
import { NavDropdown } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { asyncSetLanguage } from "../../actions/languageList";
import { getLogout } from "../../actions/loginAction";
import { Badge } from "@mui/material";
import { homeSearch } from "../../actions/homeSearchAction";
import { HomeContext } from "../../context";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Modal } from "react-bootstrap";
import { useClickAway } from "@uidotdev/usehooks";
import debounce from "lodash.debounce";
import { useHistory } from "react-router-use-history";
import LocaleContext from "../LocaleContext";
import RotatingCircle from "../Loading/RotatingCircle";


const Header = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);

  const [isLoggedIn, setisLoggedIn] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); //to read query from url

  // Language Code
  const { t } = useTranslation();
  const navigate = useNavigate();
  const homeData = useSelector((state) => state.home);

  // Language List
  const languageList = useSelector((state) => state.languageList);
  const languageReducer = useSelector((state) => state.languageReducer);
  const checklogin = useSelector((state) => state.login);
  const languageData = JSON.parse(localStorage.getItem("language"));
  const cartCount = useSelector((state) => state.cartCountReducer);
  const profileReducer = useSelector((state) => state.profileReducer);
  const [userType, setUserType] = useState("");
  const [headerLogo, setHeaderLogo] = useState("");
  const [searchFlag, setSearchFlag] = useState(true);
  // const [searchFileData, setSearchFileData] = useState([]);

  //shivani start
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const handleSearchModalOpen = () => {
    setShowSearchModal(true);
  };
  const handleSearchModalClose = () => {
    setShowSearchModal(false);
  };
  //shivani ends

  const {
    searchParameter,
    setSearchParameter,
    setSearchList,
    searchValue,
    setSearchValue,
    searchList,
    searchListLoader,
    setSearchListLoder,
    setSlug,
  } = useContext(HomeContext);

  const [searchListNew, setSearchListNew] = useState([]);
  const searchRefNew = useClickAway(() => {
    setSearchList([]);
  });

  useEffect(() => {
    setSearchListNew(searchList);
  }, [searchList]);

  useEffect(() => {
    if (homeData && homeData.settings) {
      setHeaderLogo(homeData.settings.header_logo);
    }
  }, [homeData]);

  useEffect(() => {
    setUserType(profileReducer.user_type);
  }, [profileReducer]);

  useEffect(() => {
    setisLoggedIn(checklogin);
  }, [checklogin]);

  useEffect(() => {
    // dispatch(asyncGetLanguagesList())
    setLanguagesList(languageList);
  }, [languageList, languagesList]);

  function changeLocale(shortCode, language_id, direction, flag) {
    i18n.changeLanguage(shortCode);
    dispatch(
      asyncSetLanguage({
        short_code: shortCode,
        language: language_id,
        direction: direction,
        flag: flag,
      })
    );
    setIsLoading(true);
    setTimeout(() => {
      let element = document.getElementById("scrollToTop");
      element.scrollIntoView({ behavior: `smooth`, block: "center" });
    }, 100);
  }

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 1200);
  }, [languageReducer]);

  const { SetLoginKey } = useContext(LocaleContext);
  const [ModalLogin, setModalLogin] = useState(false);
  const showModal = (status) => {
    SetLoginKey(status);
    setModalLogin(true);
    // setTimeout(() => {              //don't remove setTimeout
    // }, 3000)
  };

  const closeLoginModal = () => {
    setModalLogin(false);
  };

  const search = (term) => {
    term.length >= 3 ? setSearchListLoder(true) : setSearchListLoder(false);
    setSearchParameter(term);
    debouncedSearch();
  };

  const debouncedSearch = debounce(() => {
    //State will be set after the delay of given time after user stops typing
    setSearchFlag(!searchFlag); //to call API
  }, 500);

  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (languageReducer !== "" && searchParameter.length >= 3) {
      dispatch(
        homeSearch(
          languageReducer,
          navigate,
          searchParameter,
          setSearchList,
          setSearchListLoder,
          abortControllerRef
        )
      );

      return () => {
        if (abortControllerRef.current) {
          abortControllerRef.current.cancel();
        }
      };
    } else {
      setSearchList([]);
    }
  }, [languageReducer, searchFlag]);

  const searchItem = () => {
    const { id, slug, is_product, type } = searchValue;
    if (searchValue) {
      setTimeout(() => {
        if (is_product === 1) {
          navigate(`/culture-details/${slug}`);
        } else {
          navigate(`/destination-listing/${slug}`, { state: { type: type } });
        }
      }, 100);
    }
    setSearchParameter("");
    setSearchValue("");
    setSearchList([]);
  };

  useEffect(() => {
    if (searchValue) {
      searchItem();
    }
  }, [searchValue]);

  // mobile menu
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    setIsMenuOpen(!isMenuOpen);
  };

  if (isMenuOpen) {
    document.body.classList.add("no-scroll-header");
  } else {
    document.body.classList.remove("no-scroll-header");
  }

  const goToHome = () => {
    setSlug("recommended_tours"); // To go original home page with recomeded tours data
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchParameter) {
      event.preventDefault(); // Prevent the default form submission

      const searchListData = searchListNew[0];
      if (searchListData) {
        const searchWords = searchListData?.title.split(" ");
        const title = searchParameter?.toLowerCase();
        let firstElementAsArray = [searchWords[0]];
        if (firstElementAsArray) {
          const allWordsPresent = firstElementAsArray?.every((word) =>
            title?.includes(word?.toLowerCase())
          );

          if (searchListData && allWordsPresent) {
            navigate(`/destination-listing/${searchListData?.slug}`, {
              state: { type: searchListData?.type },
            });
          } else {
            navigate(`/all-search/${searchParameter}`);
          }
        } else {
          navigate(`/all-search/${searchParameter}`);
        }
      } else {
        navigate(`/all-search/${searchParameter}`);
      }

      // setSearchParameter("");
      // setSearchValue("");
      setSearchList([]);
    }
  };

  let timeout;
  let scroll = 0;
  window.onscroll = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (document.getElementById("header") != null) {
      timeout = setTimeout(() => {
        if (window.scrollY > 50) {
          document.getElementById("header").classList.add("sticky");
          document
            .getElementById("header")
            .classList.add("animate__animated", "animate__fadeInDown");
        } else {
          document.getElementById("header").classList.remove("sticky");
          document
            .getElementById("header")
            .classList.remove("animate__animated", "animate__fadeInDown");
        }
        scroll = window.scrollY;
      }, 50);
    }
  };

  const handleSearch = () => {
    const searchListData = searchListNew[0];
    if (searchListData) {
      const searchWords = searchListData?.title.split(" ");
      const title = searchParameter?.toLowerCase();
      let firstElementAsArray = [searchWords[0]];
      if (firstElementAsArray) {
        const allWordsPresent = firstElementAsArray?.every((word) =>
          title?.includes(word?.toLowerCase())
        );

        if (searchListData && allWordsPresent) {
          navigate(`/destination-listing/${searchListData?.slug}`, {
            state: { type: searchListData?.type },
          });
        } else {
          navigate(`/all-search/${searchParameter}`);
        }
      } else {
        navigate(`/all-search/${searchParameter}`);
      }
    } else {
      navigate(`/all-search/${searchParameter}`);
    }

    setSearchList([]);
    // setSearchParameter("");
  };

  

  return (
    <>
      <div className="header_animation_main" id="scrollToTop">
        {/* <div className="header--main animate__slideInDown animate__animated"> */}
        <div className="header--main">
          <div className="header_sticky" id="header">
            <div className="container">
              <div className="row ">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                  <div className="header_logo_image">
                    <NavLink to="" onClick={() => goToHome()}>
                      {" "}
                      <img src={headerLogo} alt="" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4 search_header_main">
                  <div className="heder_new_search">
                    <div className="input_search input_search-new">
                      <input
                        className="home-search-box"
                        type="text"
                        name=""
                        id="input-search"
                        value={searchParameter}
                        onChange={(e) => search(e.target.value)}
                        onKeyUp={(e) => handleKeyPress(e)}
                        autoComplete="off"
                      />
                      <button
                        className="btn_search"
                        type="button"
                        onClick={() => {
                          if (searchParameter) {
                            handleSearch();
                          }
                        }}
                      >
                        {t("_search")}
                      </button>
                    </div>
                    <div className="search-list">
                      {searchListLoader === true ? (
                        <ul className="list-group" ref={searchRefNew}>
                          <div className="listinner-list">
                            <li className="list-group-item search-list-item">
                              <div className="rotating-circle">
                                <RotatingCircle />
                              </div>
                            </li>
                          </div>
                        </ul>
                      ) : (
                        searchListNew.length > 0 && (
                          <ul className="list-group" ref={searchRefNew}>
                            {searchListNew.map((option, index) => {
                              return (
                                <li
                                  className="list-group-item search-list-item"
                                  key={index}
                                >
                                  <NavLink
                                    onClick={() => setSearchValue(option)}
                                  >
                                    <div className="search-main-div">
                                      <div className="search-img">
                                        <img
                                          className={
                                            option.image == ""
                                              ? "change_img"
                                              : ""
                                          }
                                          src={
                                            option.image == ""
                                              ? images["location-icon-grey.png"]
                                              : option.image
                                          }
                                          style={{ height: "50px" }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="search-title">
                                        {option.title}
                                      </div>
                                    </div>
                                  </NavLink>
                                </li>
                              );
                            })}
                          </ul>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-3 col-sm-9 col-9">
                  {/* <div className="haeder_new_btn"> */}
                  <div
                    className={`${
                      isLoggedIn && userType !== "Hotel"
                        ? "haeder_new_btn newclass-login"
                        : "haeder_new_btn"
                    }`}
                  >
                    <div className="language_change_header nav-item lang_drop_down ">
                      <NavDropdown
                        title={
                          languageData && (
                            <p>
                              <img src={languageData.flag} alt="" />{" "}
                              {/* {t(languageData.title)} */}
                            </p>
                          )
                        }
                        id="basic-nav-dropdown"
                      >
                        {languagesList.length > 0
                          ? languagesList.map((value, key) => {
                              return (
                                <NavDropdown.Item
                                  // href="#0"
                                  key={key}
                                  onClick={() =>
                                    changeLocale(
                                      value.sort_code,
                                      value.id,
                                      value.direction,
                                      value.flag
                                    )
                                  }
                                >
                                  <span className="icon_lang">
                                    <img src={value.flag} alt="English" />
                                    {value.title}
                                  </span>{" "}
                                  {/* {value.title} */}
                                </NavDropdown.Item>
                              );
                            })
                          : ""}
                      </NavDropdown>
                    </div>
                    {userType !== "Hotel" && (
                      <div className="header_menu_btn cart-icon">
                        <button
                          onClick={() => {
                            navigate("/cart");
                          }}
                        >
                          <Badge badgeContent={cartCount} color="warning">
                            {" "}
                            <span className="header_menu_icon">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6.62278 4.76755C6.62277 4.76755 6.62277 4.76756 6.62277 4.76756L5.48024 7.05263H22.625L21.4825 4.76757C21.4825 4.76756 21.4825 4.76756 21.4825 4.76755C21.215 4.23262 20.6683 3.89474 20.0702 3.89474H8.03504C7.43697 3.89474 6.89025 4.23263 6.62278 4.76755ZM23.2105 8.94737H4.89474V15.5789C4.89474 17.5765 4.98469 19.0915 5.23225 20.2549C5.47684 21.4043 5.86012 22.1367 6.3958 22.6434C6.93707 23.1553 7.73435 23.532 8.99014 23.7729C10.2526 24.0152 11.8953 24.1053 14.0526 24.1053C16.2099 24.1053 17.8527 24.0152 19.1151 23.7729C20.3709 23.532 21.1682 23.1553 21.7094 22.6434C22.2451 22.1367 22.6284 21.4043 22.873 20.2549C23.1206 19.0915 23.2105 17.5765 23.2105 15.5789V8.94737ZM4.92807 3.9202C5.5165 2.74337 6.7193 2 8.03504 2H20.0702C21.386 2 22.5888 2.74337 23.1772 3.92019L23.1772 3.9202L25.0052 7.57633C25.071 7.70787 25.1053 7.85293 25.1053 8V15.5789C25.1053 17.6026 25.0171 19.2824 24.7263 20.6492C24.4324 22.0301 23.917 23.1634 23.0114 24.0199C22.1114 24.8711 20.9249 25.355 19.4722 25.6337C18.0261 25.9112 16.2355 26 14.0526 26C11.8697 26 10.0792 25.9112 8.63309 25.6337C7.18035 25.355 5.99384 24.8711 5.09385 24.0199C4.18828 23.1634 3.67283 22.0301 3.379 20.6492C3.08815 19.2824 3 17.6026 3 15.5789V8C3 7.85293 3.03424 7.70787 3.10002 7.57632L4.92807 3.9202L4.92807 3.9202ZM10.2632 12.1053C10.7864 12.1053 11.2105 12.5294 11.2105 13.0526C11.2105 14.6223 12.4829 15.8947 14.0526 15.8947C15.6223 15.8947 16.8947 14.6223 16.8947 13.0526C16.8947 12.5294 17.3189 12.1053 17.8421 12.1053C18.3653 12.1053 18.7895 12.5294 18.7895 13.0526C18.7895 15.6688 16.6688 17.7895 14.0526 17.7895C11.4365 17.7895 9.31579 15.6688 9.31579 13.0526C9.31579 12.5294 9.73994 12.1053 10.2632 12.1053Z"
                                  fill="#232A35"
                                />
                              </svg>
                            </span>{" "}
                          </Badge>
                        </button>
                      </div>
                    )}

                    {isLoggedIn === true && userType !== "Hotel" ? (
                      <div className="home-header-wishlist">
                        {userType !== "" && userType !== undefined && (
                          <NavLink
                            to={
                              userType == "Partner"
                                ? "/supplier-wishlist"
                                : userType == "Affiliate"
                                ? "/affiliate-wishlist"
                                : "/wish-list"
                            }
                          >
                            {" "}
                            <span className="header_menu_icon">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_148_1058)">
                                  <path
                                    d="M17.5 1.91699C16.3739 1.93451 15.2724 2.24885 14.3067 2.82826C13.341 3.40768 12.5453 4.23166 12 5.21699C11.4546 4.23166 10.6589 3.40768 9.6932 2.82826C8.7275 2.24885 7.62601 1.93451 6.49996 1.91699C4.7049 1.99498 3.01366 2.78025 1.79574 4.10122C0.577818 5.4222 -0.0677922 7.17152 -4.17093e-05 8.96699C-4.17093e-05 13.514 4.78596 18.48 8.79996 21.847C9.69618 22.6001 10.8293 23.013 12 23.013C13.1706 23.013 14.3037 22.6001 15.2 21.847C19.214 18.48 24 13.514 24 8.96699C24.0677 7.17152 23.4221 5.4222 22.2042 4.10122C20.9863 2.78025 19.295 1.99498 17.5 1.91699ZM13.915 20.317C13.3789 20.7684 12.7007 21.0159 12 21.0159C11.2992 21.0159 10.621 20.7684 10.085 20.317C4.94696 16.006 1.99996 11.87 1.99996 8.96699C1.9316 7.70171 2.36632 6.46072 3.20932 5.51469C4.05232 4.56866 5.23519 3.99434 6.49996 3.91699C7.76472 3.99434 8.9476 4.56866 9.7906 5.51469C10.6336 6.46072 11.0683 7.70171 11 8.96699C11 9.23221 11.1053 9.48656 11.2929 9.6741C11.4804 9.86164 11.7347 9.96699 12 9.96699C12.2652 9.96699 12.5195 9.86164 12.7071 9.6741C12.8946 9.48656 13 9.23221 13 8.96699C12.9316 7.70171 13.3663 6.46072 14.2093 5.51469C15.0523 4.56866 16.2352 3.99434 17.5 3.91699C18.7647 3.99434 19.9476 4.56866 20.7906 5.51469C21.6336 6.46072 22.0683 7.70171 22 8.96699C22 11.87 19.053 16.006 13.915 20.313V20.317Z"
                                    fill="#232A35"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_148_1058">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>{" "}
                            {/* {t("_wishlist")} */}
                          </NavLink>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {isLoggedIn === true ? (
                      <div className="user_profile">
                        {userType !== "" && userType !== undefined && (
                          <NavLink
                            to={
                              userType == "Partner"
                                ? "/dashboard"
                                : userType == "Affiliate"
                                ? "/affiliate-dashboard"
                                : userType == "Hotel"
                                ? "/hotel-user-dashboard"
                                : "/edit-profile"
                            }
                          >
                            {/* <img src={images["user_icon-2.png"]} alt="" /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              className="home-main-header-user-icon"
                            >
                              <path
                                d="M19.8773 12.5C20.3045 12.625 20.7114 12.7946 21.0977 13.0089C21.4841 13.2232 21.8886 13.5491 22.3114 13.9866C22.7341 14.4241 23.0955 14.9397 23.3955 15.5335C23.6955 16.1272 23.9455 16.8951 24.1455 17.8371C24.3455 18.779 24.4455 19.8348 24.4455 21.0045C24.4455 22.3795 23.9909 23.5558 23.0818 24.5335C22.1727 25.5112 21.0773 26 19.7955 26H8.15C6.86818 26 5.77273 25.5112 4.86364 24.5335C3.95455 23.5558 3.5 22.3795 3.5 21.0045C3.5 19.8348 3.6 18.779 3.8 17.8371C4 16.8951 4.25 16.1272 4.55 15.5335C4.85 14.9397 5.21136 14.4241 5.63409 13.9866C6.05682 13.5491 6.46136 13.2232 6.84773 13.0089C7.23409 12.7946 7.64091 12.625 8.06818 12.5C7.35 11.3839 6.99091 10.1696 6.99091 8.85714C6.99091 7.92857 7.175 7.04241 7.54318 6.19866C7.91136 5.35491 8.40909 4.625 9.03636 4.00893C9.66364 3.39286 10.4068 2.90402 11.2659 2.54241C12.125 2.1808 13.0273 2 13.9727 2C14.9182 2 15.8205 2.1808 16.6795 2.54241C17.5386 2.90402 18.2818 3.39286 18.9091 4.00893C19.5364 4.625 20.0341 5.35491 20.4023 6.19866C20.7705 7.04241 20.9545 7.92857 20.9545 8.85714C20.9545 10.1696 20.5955 11.3839 19.8773 12.5ZM13.9727 3.71429C12.5273 3.71429 11.2932 4.21652 10.2705 5.22098C9.24773 6.22545 8.73636 7.4375 8.73636 8.85714C8.73636 10.2768 9.24773 11.4888 10.2705 12.4933C11.2932 13.4978 12.5273 14 13.9727 14C15.4182 14 16.6523 13.4978 17.675 12.4933C18.6977 11.4888 19.2091 10.2768 19.2091 8.85714C19.2091 7.4375 18.6977 6.22545 17.675 5.22098C16.6523 4.21652 15.4182 3.71429 13.9727 3.71429ZM19.7955 24.2857C20.5955 24.2857 21.2795 23.9665 21.8477 23.3281C22.4159 22.6897 22.7 21.9152 22.7 21.0045C22.7 18.8705 22.3432 17.1875 21.6295 15.9554C20.9159 14.7232 19.8909 14.0759 18.5545 14.0134C17.2364 15.1473 15.7091 15.7143 13.9727 15.7143C12.2364 15.7143 10.7091 15.1473 9.39091 14.0134C8.05455 14.0759 7.02955 14.7232 6.31591 15.9554C5.60227 17.1875 5.24545 18.8705 5.24545 21.0045C5.24545 21.9152 5.52955 22.6897 6.09773 23.3281C6.66591 23.9665 7.35 24.2857 8.15 24.2857H19.7955Z"
                                fill="#232A35"
                              />
                            </svg>
                            <span>{profileReducer.first_name}</span>
                          </NavLink>
                        )}
                        <button
                          onClick={() => dispatch(getLogout(navigate, t))}
                        >
                          <span>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <g
                                id="Group_6137"
                                data-name="Group 6137"
                                transform="translate(-57 -678)"
                              >
                                <g
                                  id="Rectangle_634"
                                  data-name="Rectangle 634"
                                  transform="translate(57 678)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeWidth="1"
                                  opacity="0"
                                >
                                  <rect width="24" height="24" stroke="none" />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    fill="none"
                                  />
                                </g>
                                <g
                                  id="md-log-out"
                                  transform="translate(56.625 677.625)"
                                >
                                  <path
                                    id="Path_6278"
                                    data-name="Path 6278"
                                    d="M13.5,16.33h8.208l-1.887,1.934,1.294,1.294L25.269,15.4,21.115,11.25l-1.342,1.294,1.934,1.934H13.5v1.852Z"
                                    transform="translate(-3.894 -3.029)"
                                    fill="#8b8b8b"
                                  />
                                  <path
                                    id="Path_6279"
                                    data-name="Path 6279"
                                    d="M12.362,19.528a7.153,7.153,0,0,1,0-14.305A7.087,7.087,0,0,1,17.4,7.308L18.713,6a9.554,9.554,0,0,0-1.2-1,9.006,9.006,0,1,0,1.2,13.755L17.4,17.442a7.082,7.082,0,0,1-5.041,2.086Z"
                                    fill="#8b8b8b"
                                  />
                                  <path
                                    id="Path_6280"
                                    data-name="Path 6280"
                                    d="M31.477,18.014l-.037-.037.037-.037.037.037Z"
                                    transform="translate(-10.794 -5.602)"
                                    fill="#8b8b8b"
                                  />
                                </g>
                              </g>
                            </svg>{" "}
                          </span>
                        </button>
                      </div>
                    ) : (
                      <div className="header_menu_btn">
                        <button
                          onClick={() => {
                            showModal("login");
                          }}
                        >
                          {t("_login")}
                        </button>
                        <button
                          onClick={() => {
                            showModal("signup");
                          }}
                        >
                          {t("_signup")}
                        </button>
                      </div>
                    )}
                  </div>

                  {/* mobile menu shivi*/}
                  <div className="all_mobile_data">
                    <div className="mobile_drop_lang">
                      <div className="search-icon-mobile">
                        <button
                          onClick={handleSearchModalOpen}
                          // onHide={handleSearchModalClose}
                        >
                          <img src={images["search-header.png"]} alt="" />
                        </button>
                      </div>
                      {/* modal */}
                      <div className="modal-header">
                        <Modal
                          className="modal-header-div"
                          show={showSearchModal}
                          // onHide={handleSearchModalClose}
                        >
                          <Modal.Body className="modal-body-main">
                            <div className="heder_new_search">
                              <div className="input_search">
                                <input
                                  className="home-search-box"
                                  type="text"
                                  name=""
                                  id="input-search"
                                  value={searchParameter}
                                  // onChange={(e) => {
                                  //   setSearchParameter(e.target.value);
                                  // }}
                                  onChange={(e) => search(e.target.value)}
                                  onKeyUp={(e) => handleKeyPress(e)}
                                  // onClick={() => setClickedAway(false)}
                                />
                                <button
                                  className="btn_search"
                                  onClick={() => {
                                    setSearchParameter("");
                                    setSearchValue("");
                                    setSearchList([]);
                                    navigate(
                                      `/destination-listing/${searchParameter}`
                                    );
                                  }}
                                >
                                  {/* {t("_search")} */}
                                  <img
                                    src={images["search-header.png"]}
                                    alt=""
                                  />
                                </button>
                              </div>

                              <div className="modal-search-list">
                                <div className="search-list">
                                  {searchListNew.length > 0 && (
                                    <ul
                                      className="list-group"
                                      ref={searchRefNew}
                                    >
                                      {searchListNew.map((option, index) => {
                                        return (
                                          <li
                                            className="list-group-item search-list-item"
                                            key={index}
                                          >
                                            <NavLink
                                              onClick={() =>
                                                setSearchValue(option)
                                              }
                                            >
                                              <div className="search-main-div search-main-div-modal-home">
                                                <div className="search-img">
                                                  <img
                                                    className={
                                                      option.image == ""
                                                        ? "change_img"
                                                        : ""
                                                    }
                                                    src={
                                                      option.image == ""
                                                        ? images[
                                                            "location-icon-grey.png"
                                                          ]
                                                        : option.image
                                                    }
                                                    style={{ height: "50px" }}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="search-title">
                                                  {option.title}
                                                </div>
                                              </div>
                                            </NavLink>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* list search */}
                            {/* <div
                              className="search-list"
                            >
                              {searchListNew.length > 0 && (
                                <ul className="list-group" ref={searchRefNew}>
                                  {searchListNew.map((option, index) => {
                                    return (
                                      <li
                                        className="list-group-item search-list-item"
                                        key={index}
                                      >
                                        <NavLink onClick={() => setSearchValue(option)}>
                                          <div className="row">
                                            <div className="searchlist-location-icon col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                              <div className="search-img">
                                                <img
                                                  className={option.image == "" ? "change_img" : ""}
                                                  src={option.image == "" ? images["location-icon-grey.png"] : option.image}
                                                  style={{ height: "50px" }}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                                              <div className="search-title">
                                                {option.title}
                                              </div>
                                            </div>
                                          </div>
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div> */}
                          </Modal.Body>
                        </Modal>
                      </div>

                      <div className="language_change_header ">
                        <li className="nav-item lang_drop_down">
                          <NavDropdown
                            title={
                              languageData && (
                                <p>
                                  <img src={languageData.flag} alt="" />{" "}
                                  {/* {t(languageData.title)} */}
                                </p>
                              )
                            }
                            id="basic-nav-dropdown"
                          >
                            {languagesList.length > 0
                              ? languagesList.map((value, key) => {
                                  return (
                                    <NavDropdown.Item
                                      // href="#0"
                                      key={key}
                                      onClick={() =>
                                        changeLocale(
                                          value.sort_code,
                                          value.id,
                                          value.direction,
                                          value.flag
                                        )
                                      }
                                    >
                                      <span className="icon_lang">
                                        <img src={value.flag} alt="English" />
                                        {value.title}
                                      </span>{" "}
                                    </NavDropdown.Item>
                                  );
                                })
                              : ""}
                          </NavDropdown>
                        </li>
                      </div>
                    </div>

                    <div className="nav_mobile_main">
                      <div className="mobile_all">
                        <div className="mobile_drawer">
                          <button
                            className="Btn_show_drawer"
                            onClick={toggleDrawer}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 30 30"
                              width="30px"
                              height="30px"
                            >
                              <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z" />
                            </svg>
                          </button>
                          {isMenuOpen && (
                            <Drawer
                              open={isOpen}
                              onClose={toggleDrawer}
                              direction="left"
                              className="mobile_nav_menu"
                            >
                              <div className="drawer_nav_mobile">
                                <div className="mobile_logo">
                                  <div className="header_logo_image">
                                    {/* <img src={headerLogo} alt="" /> */}
                                    <img src={images["logo.png"]} alt="" />
                                  </div>
                                </div>

                                <ul>
                                  <li>
                                    {" "}
                                    <NavLink to="/">
                                      {" "}
                                      <span className="header_menu_icon">
                                        {" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="28"
                                          height="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                        >
                                          <g clipPath="url(#clip0_148_1070)">
                                            <path
                                              d="M25.121 11.0691L17.536 3.48311C16.5973 2.5471 15.3257 2.02148 14 2.02148C12.6743 2.02148 11.4028 2.5471 10.464 3.48311L2.87901 11.0691C2.59944 11.3469 2.37778 11.6774 2.22689 12.0415C2.07601 12.4056 1.99889 12.796 2.00001 13.1901V23.0071C2.00001 23.8028 2.31608 24.5658 2.87869 25.1284C3.4413 25.691 4.20436 26.0071 5.00001 26.0071H23C23.7957 26.0071 24.5587 25.691 25.1213 25.1284C25.6839 24.5658 26 23.8028 26 23.0071V13.1901C26.0011 12.796 25.924 12.4056 25.7731 12.0415C25.6222 11.6774 25.4006 11.3469 25.121 11.0691ZM17 24.0071H11V20.0731C11 19.2775 11.3161 18.5144 11.8787 17.9518C12.4413 17.3892 13.2044 17.0731 14 17.0731C14.7957 17.0731 15.5587 17.3892 16.1213 17.9518C16.6839 18.5144 17 19.2775 17 20.0731V24.0071ZM24 23.0071C24 23.2723 23.8947 23.5267 23.7071 23.7142C23.5196 23.9018 23.2652 24.0071 23 24.0071H19V20.0731C19 18.747 18.4732 17.4753 17.5355 16.5376C16.5979 15.5999 15.3261 15.0731 14 15.0731C12.6739 15.0731 11.4022 15.5999 10.4645 16.5376C9.5268 17.4753 9.00001 18.747 9.00001 20.0731V24.0071H5.00001C4.7348 24.0071 4.48044 23.9018 4.29291 23.7142C4.10537 23.5267 4.00001 23.2723 4.00001 23.0071V13.1901C4.00094 12.9251 4.1062 12.6711 4.29301 12.4831L11.878 4.90011C12.4417 4.33904 13.2047 4.02405 14 4.02405C14.7953 4.02405 15.5583 4.33904 16.122 4.90011L23.707 12.4861C23.8931 12.6734 23.9983 12.9261 24 13.1901V23.0071Z"
                                              fill="#232A35"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_148_1070">
                                              <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                                transform="translate(2 2)"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>{" "}
                                      </span>{" "}
                                      {t("_home")}
                                    </NavLink>{" "}
                                  </li>
                                  <li>
                                    {" "}
                                    <NavLink
                                      to={
                                        userType === "Partner"
                                          ? "/supplier-wishlist"
                                          : "/wish-list"
                                      }
                                    >
                                      {" "}
                                      <span className="header_menu_icon">
                                        {" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <g clipPath="url(#clip0_148_1058)">
                                            <path
                                              d="M17.5 1.91699C16.3739 1.93451 15.2724 2.24885 14.3067 2.82826C13.341 3.40768 12.5453 4.23166 12 5.21699C11.4546 4.23166 10.6589 3.40768 9.6932 2.82826C8.7275 2.24885 7.62601 1.93451 6.49996 1.91699C4.7049 1.99498 3.01366 2.78025 1.79574 4.10122C0.577818 5.4222 -0.0677922 7.17152 -4.17093e-05 8.96699C-4.17093e-05 13.514 4.78596 18.48 8.79996 21.847C9.69618 22.6001 10.8293 23.013 12 23.013C13.1706 23.013 14.3037 22.6001 15.2 21.847C19.214 18.48 24 13.514 24 8.96699C24.0677 7.17152 23.4221 5.4222 22.2042 4.10122C20.9863 2.78025 19.295 1.99498 17.5 1.91699ZM13.915 20.317C13.3789 20.7684 12.7007 21.0159 12 21.0159C11.2992 21.0159 10.621 20.7684 10.085 20.317C4.94696 16.006 1.99996 11.87 1.99996 8.96699C1.9316 7.70171 2.36632 6.46072 3.20932 5.51469C4.05232 4.56866 5.23519 3.99434 6.49996 3.91699C7.76472 3.99434 8.9476 4.56866 9.7906 5.51469C10.6336 6.46072 11.0683 7.70171 11 8.96699C11 9.23221 11.1053 9.48656 11.2929 9.6741C11.4804 9.86164 11.7347 9.96699 12 9.96699C12.2652 9.96699 12.5195 9.86164 12.7071 9.6741C12.8946 9.48656 13 9.23221 13 8.96699C12.9316 7.70171 13.3663 6.46072 14.2093 5.51469C15.0523 4.56866 16.2352 3.99434 17.5 3.91699C18.7647 3.99434 19.9476 4.56866 20.7906 5.51469C21.6336 6.46072 22.0683 7.70171 22 8.96699C22 11.87 19.053 16.006 13.915 20.313V20.317Z"
                                              fill="#232A35"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_148_1058">
                                              <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </span>{" "}
                                      {t("_wishlist")}
                                    </NavLink>{" "}
                                  </li>
                                  <li>
                                    {" "}
                                    <NavLink to="/cart">
                                      <Badge
                                        badgeContent={cartCount}
                                        color="warning"
                                      >
                                        {" "}
                                        <span className="header_menu_icon">
                                          {" "}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M6.62278 4.76755C6.62277 4.76755 6.62277 4.76756 6.62277 4.76756L5.48024 7.05263H22.625L21.4825 4.76757C21.4825 4.76756 21.4825 4.76756 21.4825 4.76755C21.215 4.23262 20.6683 3.89474 20.0702 3.89474H8.03504C7.43697 3.89474 6.89025 4.23263 6.62278 4.76755ZM23.2105 8.94737H4.89474V15.5789C4.89474 17.5765 4.98469 19.0915 5.23225 20.2549C5.47684 21.4043 5.86012 22.1367 6.3958 22.6434C6.93707 23.1553 7.73435 23.532 8.99014 23.7729C10.2526 24.0152 11.8953 24.1053 14.0526 24.1053C16.2099 24.1053 17.8527 24.0152 19.1151 23.7729C20.3709 23.532 21.1682 23.1553 21.7094 22.6434C22.2451 22.1367 22.6284 21.4043 22.873 20.2549C23.1206 19.0915 23.2105 17.5765 23.2105 15.5789V8.94737ZM4.92807 3.9202C5.5165 2.74337 6.7193 2 8.03504 2H20.0702C21.386 2 22.5888 2.74337 23.1772 3.92019L23.1772 3.9202L25.0052 7.57633C25.071 7.70787 25.1053 7.85293 25.1053 8V15.5789C25.1053 17.6026 25.0171 19.2824 24.7263 20.6492C24.4324 22.0301 23.917 23.1634 23.0114 24.0199C22.1114 24.8711 20.9249 25.355 19.4722 25.6337C18.0261 25.9112 16.2355 26 14.0526 26C11.8697 26 10.0792 25.9112 8.63309 25.6337C7.18035 25.355 5.99384 24.8711 5.09385 24.0199C4.18828 23.1634 3.67283 22.0301 3.379 20.6492C3.08815 19.2824 3 17.6026 3 15.5789V8C3 7.85293 3.03424 7.70787 3.10002 7.57632L4.92807 3.9202L4.92807 3.9202ZM10.2632 12.1053C10.7864 12.1053 11.2105 12.5294 11.2105 13.0526C11.2105 14.6223 12.4829 15.8947 14.0526 15.8947C15.6223 15.8947 16.8947 14.6223 16.8947 13.0526C16.8947 12.5294 17.3189 12.1053 17.8421 12.1053C18.3653 12.1053 18.7895 12.5294 18.7895 13.0526C18.7895 15.6688 16.6688 17.7895 14.0526 17.7895C11.4365 17.7895 9.31579 15.6688 9.31579 13.0526C9.31579 12.5294 9.73994 12.1053 10.2632 12.1053Z"
                                              fill="#232A35"
                                            />
                                          </svg>
                                        </span>{" "}
                                      </Badge>
                                      {t("_cart")}
                                    </NavLink>{" "}
                                  </li>

                                  {isLoggedIn === true ? (
                                    <li>
                                      <div className="user_profile">
                                        <NavLink
                                          to={
                                            userType == "Partner"
                                              ? "/dashboard"
                                              : userType == "Affiliate"
                                              ? "/affiliate-dashboard"
                                              : userType == "Hotel"
                                              ? "/hotel-user-dashboard"
                                              : "/edit-profile"
                                          }
                                        >
                                          <img
                                            src={images["user_icon.png"]}
                                            alt=""
                                          />
                                          <span>
                                            {profileReducer.first_name}
                                          </span>
                                        </NavLink>
                                      </div>
                                    </li>
                                  ) : (
                                    <li>
                                      <div className="header_menu_btn">
                                        <button
                                          onClick={() => {
                                            showModal("login");
                                          }}
                                        >
                                          {t("_login")}
                                        </button>
                                        <button
                                          onClick={() => {
                                            showModal("signup");
                                          }}
                                        >
                                          {t("_signup")}
                                        </button>
                                      </div>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </Drawer>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginModal LoginModal={ModalLogin} LogincloseModal={closeLoginModal} />
    </>
  );
};

export default Header;

import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import { setProviderReadPolicy } from "../../actions/providerAction";

const ReadPolicyModal = (props) => {
  const { t } = useTranslation();
  const { isReviewModal, setIsReviewModal } = props;
  const languageReducer = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalClose = async () => {
    setIsReviewModal(false);

    if (languageReducer !== "") {
      dispatch(setProviderReadPolicy(languageReducer, navigate));
    }
  };

  return (
    <div className="modal_main">
      <Modal
        className=" review_modal_ payment_confirmation_modal"
        show={isReviewModal}
        onHide={modalClose}
        centered
      >
        <div className="modal-body">
          <div className="row">
            <div className="entry_cross_btn cursor-pointer">
              <span onClick={modalClose}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="red"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.27916 12.7208C1.45797 12.8996 1.70044 13 1.95327 13C2.2061 13 2.44857 12.8996 2.62737 12.7208L7 8.34821L11.3726 12.7208C11.5514 12.8996 11.7939 13 12.0467 13C12.2996 13 12.542 12.8996 12.7208 12.7208C12.8996 12.542 13 12.2996 13 12.0467C13 11.7939 12.8996 11.5514 12.7208 11.3726L8.34821 7L12.7208 2.62737C12.8996 2.44857 13 2.2061 13 1.95327C13 1.70044 12.8996 1.45797 12.7208 1.27916C12.542 1.10041 12.2996 1 12.0467 1C11.7939 1 11.5514 1.10041 11.3726 1.27916L7 5.65179L2.62737 1.27916C2.44857 1.10041 2.2061 0.999999 1.95327 0.999999C1.70044 0.999999 1.45797 1.10041 1.27916 1.27916C1.10042 1.45796 1 1.70044 1 1.95327C1 2.20609 1.10042 2.44857 1.27916 2.62737L5.65179 7L1.27916 11.3726C1.10041 11.5514 1 11.7939 1 12.0467C1 12.2996 1.10041 12.542 1.27916 12.7208Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          <div className="gift_modal_pop p-2">
            <div className="gift_pop_form">
              <h4 className="text_heading">
                {t("_important_update_partner_terms_conditions")}
              </h4>
              <p className="text-center">{t('_we_have_made_updates_to_our_supplier_terms_to_ensure_greater_clarity_and_alignment_with_industry_standards_please_review_these_changes_carefully_to_stay_compliant_with_our_partnership_requirements')}

                <NavLink className="read_link"  target="_blank"
                  to={"/partner-terms-condition"}>{t('_read_the_updated_terms_conditions')}</NavLink>
              </p>
              <div className="modal_button">
                <button
                  type="button"
                  className="remove"
                  onClick={modalClose}
                >
                  {t("_i_accept_that")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReadPolicyModal;
